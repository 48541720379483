// JavaScript Document

$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});

var $document = $(document),
    $element = $('.nav_holder'),
    className = 'leftTop';

$document.scroll(function() {
  //$element.toggleClass(className, $document.scrollTop() >= 350);
});

